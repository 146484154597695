<template>
  <div v-if="notifications" class="notifications__block main-block">
    <div class="main-block__head">
      <div class="flex">
        <h1 class="main-block__title">Уведомления</h1>
        <span class="notifications__head-number default-btn">{{ notifications.length }}</span>
      </div>
      <img class="notifications__head-icon" src="@/assets/png/select-icon.png" alt="" />
    </div>
    <ul v-if="notifications" class="notifications__items main-block__box">
      <li
        v-for="item in notifications"
        :key="item.id"
        class="notifications__item flex"
        @click="$router.push(`/route/${item.id}`)"
      >
        <img class="notifications__img" src="@/assets/png/inblock-message.png" alt="" />
        <div class="notifications__box flex">
          <div class="notifications__text-block">
            <p class="notifications__name">000000{{ item.id }}</p>
            <p class="notifications__text" />
            <p class="notifications__text">
              {{ item.reason }}
            </p>
          </div>
          <p class="notifications__name">
            {{ item.user.name }}
          </p>
          <div class="notifications__info-block">
            <div class="notifications__date-box flex">
              <label class="notifications__date" for="">{{ item.date }}</label>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      selected: null,
      options: [
        { value: null, text: "Помеченным" },
        { value: "a", text: "Помеченным" },
      ],
    };
  },
  computed: {
    ...mapState(["notifications"]),
  },
  created() {
    if(!this.notifications)
      this.$store.dispatch("getNotifications");
  },
  methods: {
    dateProduct(value) {
      if (!value) {
        return;
      }
      const date = value.split("T")[0].split("-").reverse();
      const arrDate = [
        "Янв",
        "Фев",
        "Мар",
        "Апр",
        "Май",
        "Июнь",
        "Июль",
        "Авг",
        "Сен",
        "Окт",
        "Ноя",
        "Дек",
      ];
      for (let i = 1; i <= 12; i++) {
        if ((date[1] * 10) / 10 === i) {
          date[1] = arrDate[i - 1];
        }
      }
      return date[1] + " " + date[0].split(" ")[0];
    },
  },
};
</script>

<style scoped>
.notifications__block {
  min-height: 350px;
  padding: 20px 20px 10px 20px;
}
.notifications__head-number {
  padding: 5px;
  margin-left: 10px;
  height: auto;
  border-radius: 5px;
  font-size: 12px;
  line-height: 14px;
}
.notifications__head-icon {
  transform: rotate(270deg);
}
.notifications__items {
  max-height: 270px;
}
.notifications__item {
  margin-top: 6px;
  padding: 10px 0 0 0;
  align-items: flex-start;
  cursor: pointer;
  border-radius: 5px;
}
.notifications__item:hover {
  background-color: #f2f2f2;
}
.notifications__item:not(:last-child) {
  height: 65px;
}
.notifications__item:not(:last-child) .notifications__box {
  border-bottom: 1px solid #f2f2f8;
  padding-bottom: 10px;
}
.notifications__img {
  width: 15%;
  height: 30px;
  max-width: 30px;
  margin-right: 10px;
}
.notifications__box {
  font-family: "Product Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  /*flex-direction: column;*/
  align-items: start;
  width: 100%;
  position: relative;

  gap: 0.5rem;
}
.notifications__text-block {
  width: 65%;
}
.notifications__info-block {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  justify-content: flex-end;
}
.notifications__info {
  width: 35%;
}
.notifications__name {
  width: 100%;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  margin-bottom: 2px;
}
.notifications__text {
  font-family: "Product Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #707070;
  margin: 0;
}
.notifications__label {
  font-family: "Product Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #acacac;
}
.notifications__date-box {
  width: 35%;
  text-align: right;
  justify-content: center;
}
.notifications__date {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #000;
  margin: 0 0 0 5px;
}
.notifications-favorite {
  width: 25px;
  position: absolute;
  top: 0px;
  right: 0px;
}
</style>
